import { Modal } from 'antd';

export default function SmallScreenWarningModal({ open }) {
	return (
		<>
			<Modal
				className='share-modal'
				title='Warning'
				open={open}
				width={850}
				footer={false}>
				<div className='flex justify-between items-center mt-3'>
					<p className='heading_heading_4'>Small screen detected</p>
				</div>

				<div className='mt-[2.81rem]'>
					<span className='paragraph_paragraph_2 !text-black333'>
						We have detected that you are currently using a device with a small screen. For ease of use,
                        this feature has been disabled on smaller screens. Please use a bigger
						screen or upload your documents directly to the system.
					</span>
				</div>
			</Modal>
		</>
	);
}
