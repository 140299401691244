import { useNavigate } from 'react-router-dom/dist';
import Header from '../../layouts/Header';
import { useRef, useState } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { customToast } from '../../notifications';
import DistStepFive from './distress_steps/DistSteppFive';
import DistStepSix from './distress_steps/DistStepSix';
import { fetchDistress } from '../../features/distress/distressSlice';

export default function CheckDistress({ isUserLoggedIn }) {
	const [form] = Form.useForm();
	const formRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { distressLoading } = useSelector((state) => state.distress);

	const [current, setCurrent] = useState(0);

	const steps = [
		{
			title: 'Third',
			content: <DistStepFive />,
		},
		{
			title: 'Last',
			content: <DistStepSix />,
		},
	];

	function handleBack() {
		navigate(-1);
	}

	async function onFinish(value) {        
		const res = await dispatch(fetchDistress(value));
		if (res?.payload?.length) {
			await form.resetFields();
			await setCurrent(1);
		} else {			
			await customToast({
				content: "An error has occured. Please try again later.",
				bdColor: 'error',
			});
		}
	}

	return (
		<>
			{isUserLoggedIn ? null : <Header />}
			<div className='w-full flex flex-col justify-center items-center p-3'>
				<div className='w-full lg:w-[55.6875rem]'>
					<div
						style={{
							borderRadius: '0rem 0rem 0.25rem 0.25rem',
						}}
						className='border border-blueDark'>
						<div className='p-[2.5rem] bg-blueDark flex items-center justify-center w-full'>
							<button
								onClick={handleBack}
								className='bg-white rounded-full w-[2.5rem] h-[2.5rem] flex justify-center items-center'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'>
									<path
										d='M14.1669 9.16666H7.84191L10.5919 6.42499C10.7488 6.26807 10.837 6.05524 10.837 5.83333C10.837 5.61141 10.7488 5.39858 10.5919 5.24166C10.435 5.08474 10.2222 4.99658 10.0002 4.99658C9.77832 4.99658 9.56549 5.08474 9.40857 5.24166L5.24191 9.40833C5.16604 9.48758 5.10657 9.58103 5.06691 9.68333C4.98356 9.88621 4.98356 10.1138 5.06691 10.3167C5.10657 10.419 5.16604 10.5124 5.24191 10.5917L9.40857 14.7583C9.48604 14.8364 9.57821 14.8984 9.67976 14.9407C9.78131 14.983 9.89023 15.0048 10.0002 15.0048C10.1102 15.0048 10.2192 14.983 10.3207 14.9407C10.4223 14.8984 10.5144 14.8364 10.5919 14.7583C10.67 14.6809 10.732 14.5887 10.7743 14.4871C10.8166 14.3856 10.8384 14.2767 10.8384 14.1667C10.8384 14.0566 10.8166 13.9477 10.7743 13.8462C10.732 13.7446 10.67 13.6525 10.5919 13.575L7.84191 10.8333H14.1669C14.3879 10.8333 14.5999 10.7455 14.7562 10.5892C14.9124 10.433 15.0002 10.221 15.0002 9.99999C15.0002 9.77898 14.9124 9.56702 14.7562 9.41074C14.5999 9.25446 14.3879 9.16666 14.1669 9.16666Z'
										fill='#147CBC'
									/>
								</svg>
							</button>
							<span className='heading_heading_1 ml-[2rem]'>
								Distress reporting form (Kenyan Citizens)
							</span>
						</div>
						<div className='p-[2.5rem] bg-white flex items-center justify-center w-full'>
							<span className='heading_heading_2'>Toll Free Contact: </span>
							<span className='heading_heading_2 !text-[#C8001B] ml-[1.25rem]'>
								0800 222 223
							</span>
						</div>
					</div>

					<div
						style={{
							borderRadius: '0rem 0rem 0.25rem 0.25rem',
						}}
						className='border border-blueDark mt-[0.75rem] flex flex-col justify-center items-center py-[4.69rem] w-full'>
						<Form
							onFinish={onFinish}
							layout='vertical'
							ref={formRef}
							name='control-ref'
							className=''
							style={{
								maxWidth: '100%',
							}}
							form={form}
							disabled={distressLoading}>
							<div className='flex justify-center flex-col items-center'>
								{steps[current].content}
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
