import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Modal, Select, Spin, Tag, Upload, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../features/auth/authSlice';
import { fetchLanguages } from '../../../features/dropDowns/dropDownSlice';
import dayjs from 'dayjs';
import { formatImgPath } from '../../../utils';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
	deleteSkill,
	fetchSkillCategory,
	fetchSkills,
	saveSkill,
} from '../../../features/skill/skillSlice';
import { customToast } from '../../../notifications';

const { TextArea } = Input;

const url = process.env.REACT_APP_API_BASE_URL;

let timeout;
let currentValue;

const fetchFunc = (value, callback) => {
	if (timeout) {
		clearTimeout(timeout);
		timeout = null;
	}
	currentValue = value;

	const fake = () => {
		fetch(
			`${url}/nea/fetchGlobalSkills.action?gsklName=${
				value?.length > 0 ? value : ' '
			}`
		)
			.then((response) => response.json())
			.then((d) => {
				if (currentValue === value) {
					const data = d?.data?.result
						?.sort((a, b) => a?.gsklName?.localeCompare(b?.gsklName))
						?.map((item) => ({
							value: item?.gsklId,
							text: item?.gsklName,
						}));
					callback(data);
				}
			});
	};
	if (value) {
		timeout = setTimeout(fake, 300);
	}
};

const tagRender = (props) => {
	const { label, value, closable, onClose } = props;
	const onPreventMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};
	return (
		<Tag
			color={value}
			onMouseDown={onPreventMouseDown}
			closable={closable}
			onClose={onClose}
			style={{
				marginRight: 3,
			}}>
			{label}
		</Tag>
	);
};

export default function ProfileInfoModal({ open, handleCancel }) {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { authLoading, user } = useSelector((state) => state.auth);
	const { skillLoading, mySkills } = useSelector((state) => state.skill);
	const { languageOptions } = useSelector((state) => state.dropDown);

	let skills = [];

	mySkills?.forEach((item) => {
		skills.push(item?.sklGsklId);
	});

	const [skillsList, setskillsList] = useState([]);
	const [value, setValue] = useState(skills);
	const [skillData, setskillData] = useState([]);

	const usrObj = {
		usrId: user?.usrId,
	};

	const handleSearch = (newValue) => {
		fetchFunc(newValue, setskillData);
	};

	const handleSkillChange = async (newValue) => {
		await setValue(newValue);
		let newArr = await [...newValue];
		let val = await newArr?.pop();
		const skillObj = {
			sklUsrId: user?.usrId,
			sklGsklId: val,
			sklType: 'PERSONAL',
		};

		const res = await dispatch(saveSkill(skillObj));
		
		if (res?.payload?.success) {
			await handleFetchSkills();
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
			});
		}
	};

	async function handleFetchSkills() {
		await dispatch(fetchSkills(usrObj));
	}

	async function handleDeleteSkill(id) {
		let skillObj = mySkills?.find((item) => item?.sklGsklId === id);
		if (skillObj?.sklId) {
			await dispatch(deleteSkill(skillObj?.sklId));
			await handleFetchSkills();
		}
	}

	async function handleDropDowns() {
		await dispatch(fetchLanguages());
	}

	async function handleFetchSKillCategory() {
		const res = await dispatch(fetchSkillCategory());
		if (res?.payload?.success) {
			let skills = [];

			await res?.payload?.data?.result?.forEach((item) => {
				skills.push({
					label: item?.sklcatName,
					value: item?.sklcatName,
				});
			});

			await setskillsList(skills);
		}
	}

	const onFinish = async (values) => {
		values.usrId = user?.usrId;
		values.usrNationalId = user?.usrNationalId;

		if (values?.usrLanguages) {
			values.usrLanguages = values?.usrLanguages?.toString();
		}

		if (values?.usrTitle) {
			values.usrTitle = values?.usrTitle?.toString();
		}

		await dispatch(updateUser(values));
		await handleCancel();
	};

	useEffect(() => {}, [languageOptions, skillsList, skillData, mySkills]);

	useEffect(() => {
		handleDropDowns();
		handleSearch(' ');
	}, []);

	return (
		<>
			<Modal
				className='share-modal'
				title='Edit your bio'
				open={open}
				onCancel={handleCancel}
				width={850}
				footer={false}>
				<div className='flex justify-between items-center mt-3'>
					<p className='heading_heading_4'>Edit your bio</p>
					<button onClick={() => handleCancel()}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z'
								fill='#212121'
							/>
						</svg>
					</button>
				</div>

				<div className='w-full mt-[1rem]'>
					<span className='paragraph_paragraph_2 !text-black333'>
						Tell future employers a little more about yourself.
					</span>
				</div>

				<div className='mt-[2.81rem]'>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: '100%',
							marginTop: 20,
						}}
						form={form}
						scrollToFirstError={true}
						initialValues={{
							...user,
							usrLanguages: user?.usrLanguages?.length
								? user?.usrLanguages?.split(',')
								: [],
							day: user?.usrDob ? dayjs(user?.usrDob) : null,
							month: user?.usrDob ? dayjs(user?.usrDob) : null,
							year: user?.usrDob ? dayjs(user?.usrDob) : null,
						}}>
						<div className='grid grid-cols-1 gap-[1.5rem]'>
							<Form.Item
								label='About me'
								name='usrBio'
								rules={[
									{
										required: true,
										message: 'Tell us something about you',
									},
								]}
								className='w-full table-form-item'>
								<TextArea
									placeholder='Write something about yourself'
									defaultValue={user?.usrBio}
									rows={4}
								/>
							</Form.Item>

							<Form.Item
								className='table-form-item'
								name='usrTitle'
								label='Job category'
								rules={[
									{
										required: false,
										message: 'Please select a career title',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									showSearch
									style={{
										width: '100%',
										height: '44px',
									}}
									className='step1-select-tag step1-select-icon'
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									defaultValue={
										user?.usrTitle?.length ? user?.usrTitle?.split(',') : []
									}
									options={skillsList}
									loading={skillLoading}
									onDropdownVisibleChange={() => {
										handleFetchSKillCategory();
									}}
								/>
							</Form.Item>

							<Form.Item
								className='table-form-item'
								label='Skills'
								rules={[
									{
										required: true,
										message: 'Please select atleast 1 language',
									},
								]}>
								<Select
									style={{
										width: '100%',
										height: '44px',
									}}
									maxTagCount={2}
									maxCount={8}
									loading={skillLoading}
									value={value}
									showSearch
									tagRender={tagRender}
									id='search-select'
									mode='multiple'
									placeholder={'Search Skills'}
									optionFilterProp='label'
									onChange={handleSkillChange}
									onDeselect={handleDeleteSkill}
									onSearch={handleSearch}
									onFocus={() => handleSearch(' ')}
									options={(skillData || []).map((d) => ({
										value: d.value,
										label: d.text,
									}))}
								/>
							</Form.Item>

							<Form.Item
								className='table-form-item'
								name='usrLanguages'
								label='Languages'
								rules={[
									{
										required: true,
										message: 'Please select atleast 1 language',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									mode='multiple'
									maxTagCount={2}
									tagRender={tagRender}
									style={{
										width: '100%',
										height: '44px',
									}}
									showSearch
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									allowClear
									options={
										languageOptions?.length > 0 &&
										languageOptions?.map((item) => ({
											value: item?.langName,
											label: item?.langName,
										}))
									}
								/>
							</Form.Item>
						</div>

						<div className='flex items-center justify-end mt-[5rem] w-full'>
							<button
								onClick={handleCancel}
								disabled={authLoading}
								className='!text-blueDark label_label_2 w-[8.25rem] p-3'
								type='button'>
								Cancel
							</button>
							<button
								disabled={authLoading}
								className='bg-blueDark rounded-[35px] !text-white label_label_2 w-[8.25rem] p-3 ml-[0.5rem]'
								type='submit'>
								{authLoading ? <Spin /> : 'Save'}
							</button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	);
}
