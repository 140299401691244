import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import ExperienceTab from './tabs/ExperienceTab';
import EducationTab from './tabs/EducationTab';
import CertificateTab from './tabs/CertificateTab';
import { handleStepCurrent } from '../../features/profile/profileSlice';
import {
	fetchUserDisabilitys,
	updateUser,
} from '../../features/auth/authSlice';
import { fetchJobSeekerInfos } from '../../features/job/jobSlice';
import ScrollTo from '../../ScrollTo';
import JumbotronOne from '../../components/JambotronOne';
import ProfileDescription from './descriptions/ProfileDescription';
import JobSeekingDescription from './descriptions/JobSeekingDescription';
import DisabilityDescription from './descriptions/DisabilityDescription';
import SkillsTab from './tabs/SkillsTab';
import NextOfKinTab from './tabs/NextOfKinTab';
import UserDesciption from '../foreign_clearance/descriptions/UserDescription';
import BiographyDescription from './descriptions/BiographyDescription';

export default function MainProfile({ profileApplication }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);

	const profileTabs = [
		{
			label: <span className='ml-2'>Education</span>,
			key: '1',
			children: <EducationTab />,
		},
		{
			label: <span className='ml-2'>Experience</span>,
			key: '2',
			children: <ExperienceTab />,
		},
		// {
		// 	label: <span className='ml-2'>Skills</span>,
		// 	key: '3',
		// 	children: <SkillsTab />,
		// },
		{
			label: <span className='ml-2'>Statutory Documents</span>,
			key: '3',
			children: <CertificateTab />,
		},

		{
			label: <span className='ml-2'>Next of Kin</span>,
			key: '4',
			children: <NextOfKinTab />,
		},
	];

	async function handleUpdateUser() {
		let usrObj = { ...user };
		delete usrObj?.usrEncryptedPassword;
		usrObj.usrNationalId = user?.usrNationalId;
		await dispatch(updateUser(usrObj));
	}

	async function handleProfileBuilder() {
		await dispatch(handleStepCurrent(0));
		await navigate('/profile-builder');
	}

	async function handleFetchDisability() {
		const usrObj = {
			disUsrId: user?.usrId,
		};
		await dispatch(fetchUserDisabilitys(usrObj));
	}

	async function handleFetchJobSeekerInfo() {
		const usrObj = {
			jsiUsrId: user?.usrId,
		};
		await dispatch(fetchJobSeekerInfos(usrObj));
	}

	useEffect(() => {
		handleFetchDisability();
		handleFetchJobSeekerInfo();
		handleUpdateUser();
	}, []);

	return (
		<>
			<ScrollTo />
			<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px]'>
				<div
					style={{
						borderRadius: '0.5rem',
						background: 'var(--Icon-surface, #EDF8FF)',
					}}
					className='flex justify-between items-center'>
					<JumbotronOne
						text1={`Profile`}
						text2={''}
						text3={''}
						text4={
							'Fill in your profile so that future employers can see what jobs you can do. '
						}
					/>
				</div>

				<div className='flex flex-col w-full mt-[1.2rem] gap-[1.2rem]'>
					<UserDesciption profileApplication={profileApplication} />

					<BiographyDescription profileApplication={profileApplication} />

					<ProfileDescription profileApplication={profileApplication} />

					<div className='grid grid-cols-1 xl:grid-cols-2 gap-[.5rem] w-full h-auto'>
						<JobSeekingDescription profileApplication={profileApplication} />
						<DisabilityDescription profileApplication={profileApplication} />
					</div>

					<div className='bg-white px-[.75rem] lg:px-[2.5rem] py-[2rem]'>
						<div className='flex justify-between items-center'>
							<span className='heading_heading_4'>Qualifications </span>
							{profileApplication ? null : (
								<button onClick={handleProfileBuilder}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='32'
										height='32'
										viewBox='0 0 32 32'
										fill='none'>
										<path
											d='M11.5863 27.0002H6C5.73478 27.0002 5.48043 26.8948 5.29289 26.7073C5.10536 26.5197 5 26.2654 5 26.0002V20.4139C5.00012 20.149 5.10532 19.8951 5.2925 19.7077L20.7075 4.29266C20.895 4.10527 21.1493 4 21.4144 4C21.6795 4 21.9337 4.10527 22.1213 4.29266L27.7075 9.87516C27.8949 10.0627 28.0002 10.3169 28.0002 10.582C28.0002 10.8471 27.8949 11.1014 27.7075 11.2889L12.2925 26.7077C12.1051 26.8948 11.8511 27 11.5863 27.0002Z'
											stroke='#212121'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M17 8L24 15'
											stroke='#212121'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
									</svg>
								</button>
							)}
						</div>

						<div
							className='mt-[1.5rem]'
							style={{
								width: '100%',
								height: '0.0625rem',
								background: 'rgba(216, 216, 216, 0.54)',
							}}></div>

						<Tabs
							className='mt-[1rem]'
							defaultActiveKey='1'
							items={profileTabs}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
