import { Breadcrumb } from 'antd';
import JumbotronOne from '../../../components/JambotronOne';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SmallScreenWarningModal from '../SmallScreenWarningModal';

export default function CoverTemplateSelector() {
	const navigate = useNavigate();

	const [open, setopen] = useState(false);

	function handleSelectTemplate() {
		navigate('/cv-cover-letter/create/cover-letter');
	}

	useEffect(() => {
		if (window.innerWidth < 1170) {
			setopen(true);
		} else {
			setopen(false);
		}
	}, []);

	return (
		<>
			<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px]'>
				<JumbotronOne
					text1={`CV/Cover Letter`}
					text2={''}
					text3={''}
					text4={'A strong CV will help you stand out to employers.'}
				/>

				<div className='flex flex-col mt-[1.2rem] px-[.75rem] lg:px-[2.5rem] w-full pb-[11rem]'>
					<div className=''>
						<Breadcrumb
							items={[
								{
									title: <Link to='/cv-cover-letter'>Cover Letter</Link>,
								},
								{
									title: 'CV Builder',
								},
							]}
						/>
					</div>
					<span className='heading_heading_4 mt-[2.5rem]'>
						Select a template
					</span>
					<span className='paragraph_paragraph_1 mt-[1.25rem]'>
						Select a template to get started
					</span>

					<div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[2.5rem] mt-[1.69rem]'>
						<button onClick={handleSelectTemplate}>
							<div
								style={{
									borderRadius: '0.25rem',
									border: '1px solid #1B47B4',
									background: 'var(--White, #FFF)',
								}}
								className='mr-5 flex justify-center items-center w-full h-[20.2185rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M11.25 18.75V12.75H5.25V11.25H11.25V5.25H12.75V11.25H18.75V12.75H12.75V18.75H11.25Z'
										fill='#1B47B4'
									/>
								</svg>
								<span className='ml-[.38rem] label_label_2 !text-blueDark'>
									Blank
								</span>
							</div>
						</button>
					</div>
				</div>
			</div>

			<SmallScreenWarningModal open={open} />
		</>
	);
}
