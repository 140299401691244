import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileInfoModal from '../modals/ProfileInfoModal';
import { fetchSkills } from '../../../features/skill/skillSlice';

export default function BiographyDescription({ profileApplication }) {
	const dispatch = useDispatch()

	const { user } = useSelector((state) => state.auth);
	const { mySkills } = useSelector((state) => state.skill);

	const [open, setopen] = useState(false);

	const usrObj = {
		usrId: user?.usrId,
	};

    const languages = user?.usrLanguages?.split(',') ?? [] 

	function handleCancel() {
		setopen(false);
	}   
	
	async function handleFetchSkills() {
		await dispatch(fetchSkills(usrObj));
	}

	useEffect(() => {}, [user, mySkills]);

	useEffect(() => {
		handleFetchSkills()
	}, []);

	return (
		<>
			<div className='bg-white px-[.75rem] lg:px-[2.5rem] py-[2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Biography</span>
					{profileApplication ? null : (
						<button onClick={() => setopen(true)}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'>
								<path
									d='M11.5863 27.0002H6C5.73478 27.0002 5.48043 26.8948 5.29289 26.7073C5.10536 26.5197 5 26.2654 5 26.0002V20.4139C5.00012 20.149 5.10532 19.8951 5.2925 19.7077L20.7075 4.29266C20.895 4.10527 21.1493 4 21.4144 4C21.6795 4 21.9337 4.10527 22.1213 4.29266L27.7075 9.87516C27.8949 10.0627 28.0002 10.3169 28.0002 10.582C28.0002 10.8471 27.8949 11.1014 27.7075 11.2889L12.2925 26.7077C12.1051 26.8948 11.8511 27 11.5863 27.0002Z'
									stroke='#212121'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
								<path
									d='M17 8L24 15'
									stroke='#212121'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</button>
					)}
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='mt-[2rem] grid grid-cols-1 gap-[2.75rem]'>
					<div className='flex flex-col'>
						<div className='flex items-center gap-[.81rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<g clipPath='url(#clip0_3177_18210)'>
									<path
										d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM16.23 18L12 15.45L7.77 18L8.89 13.19L5.16 9.96L10.08 9.54L12 5L13.92 9.53L18.84 9.95L15.11 13.18L16.23 18Z'
										fill='black'
									/>
								</g>
								<defs>
									<clipPath id='clip0_3177_18210'>
										<rect width='24' height='24' fill='white' />
									</clipPath>
								</defs>
							</svg>
							<span className='label_label_1'>About me</span>
						</div>
						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrBio ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<div className='flex items-center gap-[.81rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<g clipPath='url(#clip0_3177_18210)'>
									<path
										d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM16.23 18L12 15.45L7.77 18L8.89 13.19L5.16 9.96L10.08 9.54L12 5L13.92 9.53L18.84 9.95L15.11 13.18L16.23 18Z'
										fill='black'
									/>
								</g>
								<defs>
									<clipPath id='clip0_3177_18210'>
										<rect width='24' height='24' fill='white' />
									</clipPath>
								</defs>
							</svg>
							<span className='label_label_1'>Skills</span>
						</div>
						<div className='flex items-center flex-wrap gap-[.75rem] mt-[0.75rem]'>
							{mySkills?.length ? (
								<>
									{mySkills?.map((item) => {
										return (
											<>
												<div className='bg-[#E3E1FF] rounded-[3.9375rem] p-[0.75rem] flex items-center justify-center'>
													<span className='label_label_2 !text-[#262168]'>
														{item?.skillName}
													</span>
												</div>
											</>
										);
									})}
								</>
							) : (
								<span className='paragraph_paragraph_1 truncate'>
									N/A
								</span>
							)}
						</div>
					</div>

                    <div className='flex flex-col'>
						<div className='flex items-center gap-[.81rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<g clipPath='url(#clip0_3177_18210)'>
									<path
										d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM16.23 18L12 15.45L7.77 18L8.89 13.19L5.16 9.96L10.08 9.54L12 5L13.92 9.53L18.84 9.95L15.11 13.18L16.23 18Z'
										fill='black'
									/>
								</g>
								<defs>
									<clipPath id='clip0_3177_18210'>
										<rect width='24' height='24' fill='white' />
									</clipPath>
								</defs>
							</svg>
							<span className='label_label_1'>Languages</span>
						</div>
						<div className='flex items-center flex-wrap mt-[0.75rem]'>
							{languages?.length ? (
								<>
									{languages?.map((item) => {
										return (
											<>
												<div className='bg-[#E3E1FF] rounded-[3.9375rem] text-center p-[0.75rem] mr-[1.5rem] flex items-center justify-between mb-3'>
													<span className='label_label_2 !text-[#262168]'>
														{item}
													</span>
												</div>
											</>
										);
									})}
								</>
							) : (
								<span className='paragraph_paragraph_1 truncate'>
									N/A
								</span>
							)}
						</div>
					</div>
                    
				</div>
			</div>

			<ProfileInfoModal open={open} handleCancel={handleCancel} />
		</>
	);
}
