import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Form, Input, Modal, Radio, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../features/auth/authSlice';
import {
	fetchCounties,
	fetchSubCounties,
} from '../../../features/global/globalSlice';
import {
	fetchEthnicitys,
	fetchGender,
	fetchMaritalStatus,
	fetchNationalitys,
} from '../../../features/dropDowns/dropDownSlice';
import dayjs from 'dayjs';

export default function ProfileDescriptionModal({ open, handleCancel }) {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { authLoading, user } = useSelector((state) => state.auth);
	const { counties, globalLoading } = useSelector((state) => state.global);
	const {
		dropDownLoading,
		ethnicity,
		genders,
		maritalStatus,
		nationalityOptions,
	} = useSelector((state) => state.dropDown);

	const [subCounties, setsubCounties] = useState([]);
	const [employed, setemployed] = useState(
		user?.usrEmploymentStatus === 'Employed' ? true : false
	);
	const [isIntern, setisIntern] = useState(
		user?.usrInternStatus === 'Intern' ? true : false
	);

	const today = new Date();
	let dd = String(today.getDate()).padStart(2, 0);
	let mm = String(today.getMonth() + 1).padStart(2, 0);
	let yyyy = today.getFullYear() - 18;

	let minDate = `${yyyy}-${mm}-${dd}`;

	async function handleDropDowns() {
		await dispatch(fetchCounties());
		await dispatch(fetchEthnicitys());
		await dispatch(fetchMaritalStatus());
		await dispatch(fetchGender());
		await dispatch(fetchNationalitys());
	}

	async function handleFetchSubCounties(val) {
		const res = await dispatch(fetchSubCounties(val));
		setsubCounties(res?.payload);
	}

	const onFinish = async (values) => {
		values.usrId = user?.usrId;
		values.usrNationalId = user?.usrNationalId;

		values.usrEmploymentStatus =
			values?.usrEmploymentStatus === true ? 'Employed' : 'Unemployed';
		values.usrInternStatus =
			values?.usrInternStatus === true ? 'Intern' : 'N/A';

		if (values?.year || values?.month || values?.day) {
			values.usrDob = `${
				values?.year?.$y ?? new Date(user?.usrDob).getFullYear()
			}-${
				values?.month?.$M < 9
					? '0' +
					  Number((values?.month?.$M ?? new Date(user?.usrDob).getMonth()) + 1)
					: Number((values?.month?.$M ?? new Date(user?.usrDob).getMonth()) + 1)
			}-${
				values?.day?.$D < 10
					? '0' + values?.day?.$D
					: values?.day?.$D >= 10
					? values?.day?.$D
					: new Date(user?.usrDob).getDay()
			}`;
		}

		if (values?.usrLanguages) {
			values.usrLanguages = values?.usrLanguages?.toString();
		}

		if (values?.usrTitle) {
			values.usrTitle = values?.usrTitle?.toString();
		}

		await dispatch(updateUser(values));
		await handleCancel();
	};

	useEffect(() => {}, []);

	useEffect(() => {
		handleDropDowns();
		handleFetchSubCounties();
	}, []);

	return (
		<>
			<Modal
				className='share-modal'
				title='Edit personal info'
				open={open}
				onCancel={handleCancel}
				width={850}
				footer={false}>
				<div className='flex justify-between items-center mt-3'>
					<p className='heading_heading_4'>Edit personal info</p>
					<button onClick={() => handleCancel()}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z'
								fill='#212121'
							/>
						</svg>
					</button>
				</div>

				<div className='w-full mt-[1rem]'>
					<span className='paragraph_paragraph_2 !text-black333'>
						You may edit the following details under your personal info
					</span>
				</div>

				<div className='mt-[2.81rem]'>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: '100%',
							marginTop: 20,
						}}
						form={form}
						scrollToFirstError={true}
						initialValues={{
							...user,
							usrLanguages: user?.usrLanguages?.length
								? user?.usrLanguages?.split(',')
								: [],
							day: user?.usrDob ? dayjs(user?.usrDob) : null,
							month: user?.usrDob ? dayjs(user?.usrDob) : null,
							year: user?.usrDob ? dayjs(user?.usrDob) : null,
						}}>
						<div className='grid grid-cols-1 gap-[0.5rem]'>
							<Form.Item
								name='usrGender'
								label='Gender'
								rules={[
									{
										required: false,
										message: 'Gender is required',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									// disabled
									placeholder='Select gender'
									showSearch
									loading={dropDownLoading}
									style={{
										width: '100%',
										height: '44px',
									}}
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={
										genders?.length &&
										genders?.map((item) => {
											return {
												label: item?.gndName,
												value: item?.gndName,
											};
										})
									}
								/>
							</Form.Item>

							<Form.Item
								name='usrCounty'
								label='Home County'
								rules={[
									{
										required: false,
										message: 'Please select your county',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									placeholder='Select a your county'
									showSearch
									loading={globalLoading}
									style={{
										width: '100%',
										height: '44px',
									}}
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={
										counties?.length &&
										counties?.map((item) => {
											return {
												label: item?.admCounty,
												value: item?.admCounty,
											};
										})
									}
									onChange={handleFetchSubCounties}
								/>
							</Form.Item>

							<Form.Item
								name='usrConstituency'
								label='Sub county'
								rules={[
									{
										required: false,
										message: 'Please select your sub-county',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									placeholder='Select a county to get sub-counties'
									showSearch
									loading={globalLoading}
									style={{
										width: '100%',
										height: '44px',
									}}
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={
										subCounties?.length &&
										subCounties?.map((item) => {
											return {
												label: item?.admSubCounty,
												value: item?.admSubCounty,
											};
										})
									}
									// onChange={handleFetchWards}
								/>
							</Form.Item>

							<Form.Item
								name='usrMaritalStatus'
								label='Marital Status'
								rules={[
									{
										required: false,
										message: 'Please select your marital status',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									placeholder='Select your marital status'
									showSearch
									loading={dropDownLoading}
									style={{
										width: '100%',
										height: '44px',
									}}
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={
										maritalStatus?.length &&
										maritalStatus?.map((item) => {
											return {
												label: item?.marName,
												value: item?.marName,
											};
										})
									}
								/>
							</Form.Item>

							<Form.Item
								name='usrEthnicity'
								label='Ethnicity'
								rules={[
									{
										required: true,
										message: 'Please select your ethnicity',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									placeholder='Select your ethnic group'
									showSearch
									loading={dropDownLoading}
									style={{
										width: '100%',
										height: '44px',
									}}
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={
										ethnicity?.length &&
										ethnicity?.map((item) => {
											return {
												label: item?.ethName,
												value: item?.ethName,
											};
										})
									}
								/>
							</Form.Item>

							<Form.Item
								name='usrNationality'
								label='Nationality'
								rules={[
									{
										required: true,
										message: 'Nationality is required',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									showSearch
									maxCount={1}
									style={{
										width: '100%',
										height: '44px',
									}}
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									allowClear
									options={
										nationalityOptions?.length > 0 &&
										nationalityOptions?.map((item) => ({
											value: item?.natName,
											label: item?.natName,
										}))
									}
								/>
							</Form.Item>

							<Form.Item
								name='usrPassportNo'
								label='Passport no.'
								rules={[
									{
										required: false,
										message: 'Please add a passport number',
									},
								]}>
								<Input defaultValue={user?.usrPassportNo} className='input' />
							</Form.Item>

							<div>
								<p className='mb-3 font-medium'>Date of birth</p>
								<div className='flex items-center w-full'>
									<Form.Item
										name='year'
										rules={[
											{
												required: true,
												message: 'Year is required',
											},
										]}
										className='w-full'>
										<DatePicker
											maxDate={dayjs(minDate)}
											placeholder='Year'
											format={'YYYY'}
											picker='year'
										/>
									</Form.Item>

									<Form.Item
										name='month'
										rules={[
											{
												required: true,
												message: 'Month is required',
											},
										]}
										className='mr-6 w-full'>
										<DatePicker
											placeholder='Month'
											className='mx-3'
											format={'MMMM'}
											picker='month'
										/>
									</Form.Item>

									<Form.Item
										name='day'
										rules={[
											{
												required: true,
												message: 'Day is required',
											},
										]}
										className='w-full'>
										<DatePicker placeholder='Day' format={'DD'} />
									</Form.Item>
								</div>
							</div>

							<Form.Item
								name='usrEmploymentStatus'
								label='Are you currently employed?'
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}>
								<Radio.Group
									onChange={(e) => setemployed(e.target.value)}
									defaultValue={employed}>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>

							{employed === false && (
								<Form.Item
									name='usrInternStatus'
									label='Are you looking for internship?'
									rules={[
										{
											required: false,
											message: 'Field is required',
										},
									]}>
									<Radio.Group
										onChange={(e) => setisIntern(e.target.value)}
										defaultValue={isIntern}>
										<Radio value={true}>Yes</Radio>
										<Radio value={false}>No</Radio>
									</Radio.Group>
								</Form.Item>
							)}
						</div>

						<div className='flex items-center justify-end mt-[5rem] w-full'>
							<button
								onClick={handleCancel}
								disabled={authLoading}
								className='!text-blueDark label_label_2 w-[8.25rem] p-3'
								type='button'>
								Cancel
							</button>
							<button
								disabled={authLoading}
								className='bg-blueDark rounded-[35px] !text-white label_label_2 w-[8.25rem] p-3 ml-[0.5rem]'
								type='submit'>
								{authLoading ? <Spin /> : 'Save'}
							</button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	);
}
