import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	distressLoading: false,
	distress: {},
	distressActions: [],
};

export const fetchDistress = createAsyncThunk(
	'distressSlice/fetchDistress',
	async (obj) => {
		const res = await axios.get(
			`${url}/emp/fetchDistresss.action?dstUsrId=${obj?.dstUsrId ?? ''}&dstCode=${obj?.dstCode}`,
		);
		return res.data.data.result;
	},
);

export const saveDistress = createAsyncThunk(
	'distressSlice/saveDistress',
	async (obj) => {
		const res = await axios.post(`${url}/emp/saveDistress.action`, obj);
		return res.data;
	},
);

export const fetchDistressUser = createAsyncThunk(
	'distressSlice/fetchDistressUser',
	async (usrNationalId) => {
		const res = await axios.get(
			`${url}/nea/fetchUsers.action?usrNationalId=${usrNationalId}`,
		);
		return res.data.data.result;
	},
);

export const fetchDistressActions = createAsyncThunk(
	'distressSlice/fetchDestressActions',
	async () => {
		const res = await axios.get(
			`${url}/emp/fetchDestressActions.action`,
		);
		return res.data.data.result;
	},
);

export const distressSlice = createSlice({
	name: 'distress',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchDistressActions.pending, (state) => {
				state.distressLoading = true;
			})
			.addCase(fetchDistressActions.fulfilled, (state, action) => {
				state.distressLoading = false;
				state.distressActions = action.payload
			})
			.addCase(fetchDistressActions.rejected, (state) => {
				state.distressLoading = false;
				state.distressActions = []
			})

			.addCase(fetchDistressUser.pending, (state) => {
				state.distressLoading = true;
			})
			.addCase(fetchDistressUser.fulfilled, (state) => {
				state.distressLoading = false;
			})
			.addCase(fetchDistressUser.rejected, (state) => {
				state.distressLoading = false;
			})

            .addCase(saveDistress.pending, (state) => {
				state.distressLoading = true;
			})
			.addCase(saveDistress.fulfilled, (state) => {
				state.distressLoading = false;
			})
			.addCase(saveDistress.rejected, (state) => {
				state.distressLoading = false;
			})

			.addCase(fetchDistress.pending, (state) => {
				state.distressLoading = true;
			})
			.addCase(fetchDistress.fulfilled, (state, action) => {
				state.distressLoading = false;
				state.distress = action.payload[0];
			})
			.addCase(fetchDistress.rejected, (state) => {
				state.distressLoading = false;
				state.distress = [];
			});
	},
});

export default distressSlice.reducer;
export const {} = distressSlice.actions;
